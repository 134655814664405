import { parseQueryParams } from '@/utils/object';
import { get, getCdnUrl, simpleGet } from './fetch';

export function getRates(products = [], collection = []) {
  let query = '';

  if (products.length) {
    query += `product_stars_ids=${products}&`;
  }

  if (collection.length) {
    query += `collection_stars_ids=${collection}`;
  }

  return get(`/plugins/fetch-stars?${query}`);
}

export function getExampleWidgets() {
  return get('/get-example-widgets');
}

export function getProduct(params = {}) {
  return get('/plugins/product_api?' + parseQueryParams(params));
}

export function getAllProductData(params = {}) {
  return get('/plugins/shop_api?' + parseQueryParams(params));
}

export function getBadgeData(params = {}) {
  return get('/plugins/badge_api?' + parseQueryParams(params));
}

export function getConfigData(params = {}) {
  return get('/plugins/shop_config?' + parseQueryParams(params));
}

export function translateReview(id, target) {
  return get(`/plugins/translate?review_id=${id}&target_language=${target}`);
}

export function getLangData() {
  return simpleGet(getCdnUrl('i18n/lang.json')).then((d) => JSON.parse(d));
}

import { DYNAMIC_NODES, PRODUCT_ID_SELECTOR } from '@/utils/constants';

//TODO: handle events
import { renderVue } from '@/libs/VueRender';

export default function () {
  function getPlatformProductId() {
    const metafield = document.querySelector('[data-platform-product-id]');
    return metafield ? metafield.dataset.platformProductId : '';
  }

  function genRenderFunc(prop) {
    const platformProductId = getPlatformProductId();
    return (nodes) => {
      nodes.forEach(async (n) => {
        const productId = n.dataset.productId || platformProductId;
        // remove product id to exclude finished elements from search
        delete n.dataset.productId;

        await renderVue(n, { [prop]: true, productId });
        n.id = 'opinew-plugin';
      });
    };
  }
  return DYNAMIC_NODES.map(([selector, prop]) => [
    selector + (selector.includes('product-plugin') ? PRODUCT_ID_SELECTOR : ''),
    genRenderFunc(prop),
  ]);
}

import { mapObjKeysRecursive, snakeToCamel } from '../utils/object';

class LocalCache {
  constructor() {}

  requestPromises = {};
  dataPromises = {};

  async getCachedResponse(url) {
    if (!(url in this.requestPromises)) {
      this.requestPromises[url] = fetch(url);
    }
    if (!this.dataPromises[url] && this.requestPromises[url]) {
      this.dataPromises[url] = this.requestPromises[url].then((resp) => {
        if (!resp.ok) {
          throw new Error(
            `Invalid server resp: ${resp.statusText} (${resp.status})`
          );
        }
        return resp.json();
      });
    }
    return this.dataPromises[url];
  }

  async get(url) {
    return this.getCachedResponse(url)
      .then((d) => mapObjKeysRecursive(d, snakeToCamel))
      .catch((e) => {
        console.error(e);
      });
  }
}

export default LocalCache;

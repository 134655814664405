const consts = {
  SERVER_URL:
    window.OPW_API_ENDPOINT ||
    process.env.VUE_APP_API_ENDPOINT ||
    'http://localhost:5000',
  MAX_DISPLAYED_BODY_LENGTH: 300,
  STARS_RANGE: [1, 2, 3, 4, 5],
  EMPTY_STAR: 'empty',
  HALF_STAR: 'half',
  FULL_STAR: 'full',
  WIDGET_THEME_STYLE_CARD: 'card',
  WIDGET_THEME_STYLE_FLAT: 'flat',
  WIDGET_TOP_SECTION_STYLE_DEFAULT: 'default',
  WIDGET_TOP_SECTION_STYLE_CONDENSED: 'condensed',
  WIDGET_TOP_SECTION_STYLE_CLEAN: 'clean',
  WIDGET_TOP_SECTION_STYLE_MINIMAL: 'minimal',
  WIDGET_TOP_SECTION_STYLE_MODERN: 'modern',
  WIDGET_TOP_SECTION_STYLE_CARUSEL: 'carousel',
  WIDGET_TOP_SECTION_STYLE_MSI: 'msi',
  REQUEST_TYPES: {
    admin: 'admin',
    create: 'create',
    unverified: 'unverified',
    unverifiedCreate: 'unverifiedCreate',
    test: 'test',
    requestLink: 'link',
  },
};
export default consts;
export const PRODUCT_ID_SELECTOR = '[data-product-id]';
export const STARS_SELECTOR = 'opw-dynamic-stars';
export const COLLECTION_SELECTOR = 'opw-dynamic-stars-collection';

export const DYNAMIC_NODES = [
  ['.opinew-product-plugin-dynamic', 'prod'],
  ['.opinew-review-request-dynamic', 'request'],
  ['.opinew-badge-plugin-dynamic', 'badge'],
  ['.opinew-carousel-plugin-dynamic', 'carousel'],
  ['.opinew-all-reviews-plugin-dynamic', 'all'],
];

export const WIDGET_NODES = [
  ['opinew_product_plugin_app', 'prod'],
  ['opinew_review_request_app', 'request'],
  ['opinew_badge_plugin_app', 'badge'],
  ['opinew_carousel_plugin_app', 'carousel'],
  ['opinew_all_reviews_plugin_app', 'all'],
  ['opinew_product_floating_widget', 'floating'],
];

export const AVAILABLE_THEMES = [
  consts.WIDGET_TOP_SECTION_STYLE_DEFAULT,
  consts.WIDGET_TOP_SECTION_STYLE_MINIMAL,
  consts.WIDGET_TOP_SECTION_STYLE_MODERN,
  consts.WIDGET_TOP_SECTION_STYLE_CARUSEL,
  consts.WIDGET_TOP_SECTION_STYLE_MSI,
];

import LocalCache from '../libs/Cache';
import {
  camelToSnake,
  mapObjKeysRecursive,
  snakeToCamel,
} from '../utils/object';

const cachedFetch = new LocalCache();

export function getUrl(path = '') {
  const BASE_URL =
    window.OPW_API_ENDPOINT ||
    process.env.VUE_APP_API_ENDPOINT ||
    'http://localhost:5000';
  return path.slice(0, 4) === 'http' ? path : BASE_URL + path;
}

export function getCdnUrl(path) {
  const preload = document.querySelector(
    `link[rel="preload"][href*="opinew"][href$="${path}"]`
  );
  if (preload) {
    return preload.href;
  }

  const source =
    document.currentScript ||
    document.querySelector('script[src*=opinew][src*="/js/index."]');

  if (source) {
    return source.src.replace(/js\/index.(\w{8}\.)?js/, path);
  }
}

async function handleResponse(resp) {
  if (!resp.ok) {
    throw new Error(`Invalid server resp: ${resp.statusText} (${resp.status})`);
  }
  return mapObjKeysRecursive(await resp.json(), snakeToCamel);
}

export function simpleGet(url) {
  return cachedFetch.getCachedResponse(getUrl(url));
}

export async function get(path, params) {
  let query = '';
  if (params) {
    query = Object.entries(params).reduce(
      (q, [key, val]) => q + `${camelToSnake(key)}=${val}&`,
      '?'
    );
  }
  return await cachedFetch.get(getUrl(path + query));
}

export async function post(path, data, headers) {
  const isFormData = data instanceof FormData;
  const ct = isFormData ? {} : { 'Content-Type': 'application/json' };
  const response = await fetch(getUrl(path), {
    headers: {
      ...ct,
      ...headers,
    },
    method: 'POST',
    body: isFormData
      ? data
      : JSON.stringify(mapObjKeysRecursive(data, camelToSnake)),
  });
  return handleResponse(response);
}

export async function getNoCache(url, params) {
  let query = '';
  if (params) {
    query = Object.entries(params).reduce(
      (q, [key, val]) => q + `${camelToSnake(key)}=${val}&`,
      '?'
    );
  }
  const response = await fetch(getUrl(url + query));
  return handleResponse(response);
}

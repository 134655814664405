export class Debounce {
  constructor(method, threshold = 50) {
    this.time = threshold;
    this.target = method;
  }
  timeout = null;

  run(root) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.target(root), this.time);
  }
}

//TODO: Make this work

export const emitLoad = new Debounce((root) => {
  root.$el.dispatchEvent(
    new CustomEvent('OpinewWidgetLoaded', { bubbles: true })
  );
}, 10);

export function data() {
  return {
    emitLoad,
  };
}

export function getShopId() {
  try {
    const metafield = document.querySelector('[data-opwconfig]');
    const conf = JSON.parse(metafield.dataset.opwconfig);
    return conf && conf.shop.id;
    // eslint-disable-next-line no-empty
  } catch {}
  return null;
}

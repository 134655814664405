const { getRates } = require('../api');
const { STARS_SELECTOR, COLLECTION_SELECTOR } = require('../utils/constants');

const queue = [];

export async function renderSingle({
  node,
  collectionIds = [],
  productIds = [],
}) {
  try {
    const { productStars, collectionStars } = await getRates(
      productIds,
      collectionIds
    );
    if (productStars && productStars[productIds[0]]) {
      node.innerHTML = productStars[productIds[0]] || '';
    } else if (collectionStars && collectionStars[collectionIds[0]]) {
      node.innerHTML = collectionStars[collectionIds[0]] || '';
    }
  } catch (e) {
    console.error(e);
  }
}

// eslint-disable-next-line max-lines-per-function
async function renderFunc(nodes = []) {
  const products = nodes.filter((n) =>
    [...n.classList].includes(STARS_SELECTOR)
  );
  const collections = nodes.filter((n) =>
    [...n.classList].includes(COLLECTION_SELECTOR)
  );
  const ProductIds = products.map((node) => node.dataset.productId);
  const collectionIds = collections.map((node) => node.dataset.productId);

  // add ids to queue to prevent from repeating requests
  queue.splice(0, 0, ...ProductIds, ...collectionIds);
  try {
    const { productStars, collectionStars } = await getRates(
      [...new Set(ProductIds)],
      [...new Set(collectionIds)]
    );

    if (productStars) {
      // insert stars html into nodes
      products.forEach((node) => {
        if (node.dataset.productId) {
          node.innerHTML = productStars[node.dataset.productId] || '';
          // remove product id to exclude finished elements from node search
          delete node.dataset.productId;
        }
      });
    }
    if (collectionStars) {
      // insert stars html into nodes
      collections.forEach((node) => {
        if (node.dataset.productId) {
          node.innerHTML = collectionStars[node.dataset.productId] || '';

          // remove product id to exclude finished elements from node search
          delete node.dataset.productId;
        }
      });
    }
  } catch (e) {
    console.error(e);
  }
}

export default [
  [
    `.${STARS_SELECTOR}[data-product-id],.${COLLECTION_SELECTOR}[data-product-id]`,
    renderFunc,
  ],
];

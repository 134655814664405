export function importantify(styles) {
  return Object.fromEntries(
    Object.entries(styles)
      .filter(([_, val]) => ![null, undefined].includes(val))
      .map(([key, val]) => [key, val + '!important'])
  );
}

export function snakeToCamel(str) {
  return str.replace(/_[a-z0-9]/g, (l) => l[1].toUpperCase());
}

export function camelToSnake(str) {
  return str.replace(/([A-Z]+|[0-9]+)/g, '_$1').toLowerCase();
}

export function mapObjKeys(obj, mapper) {
  return (
    obj &&
    Object.fromEntries(
      Object.entries(obj).map(([key, val]) => [mapper(key), val])
    )
  );
}

export const isObject = (o) =>
  Object.prototype.toString.apply(o) === '[object Object]';

export function mapObjKeysRecursive(obj, mapper) {
  return (
    obj &&
    Object.fromEntries(
      Object.entries(obj).map(([key, val]) => {
        const entry = [mapper(key)];
        if (Array.isArray(val) && val.every(isObject)) {
          entry.push(val.map((o) => mapObjKeysRecursive(o, mapper)));
        } else if (isObject(val)) {
          entry.push(mapObjKeysRecursive(val, mapper));
        } else {
          entry.push(val);
        }
        return entry;
      })
    )
  );
}

export function deepAssign(source, target) {
  Object.entries(source).forEach(([key, val]) => {
    if (Array.isArray(val)) {
      target[snakeToCamel(key)] = target[snakeToCamel(key)] || [];
      if (val.every((e) => typeof e === 'object')) {
        target[snakeToCamel(key)] = val.map((o) =>
          mapObjKeysRecursive(o, snakeToCamel)
        );
      } else {
        target[snakeToCamel(key)] = val;
      }
    } else if (val && typeof val === 'object') {
      target[snakeToCamel(key)] = target[snakeToCamel(key)] || {};
      if (!isObject(target[snakeToCamel(key)])) {
        target[snakeToCamel(key)] = {};
      }
      deepAssign(val, target[snakeToCamel(key)]);
    } else {
      if (val !== undefined) {
        target[snakeToCamel(key)] = val;
      }
    }
  });
}

export function flattenDict(obj) {
  return Object.fromEntries(
    Object.entries(obj).reduce((entries, [key, value]) => {
      if (typeof value == 'string') {
        entries.push([key, value]);
      } else if (isObject(value)) {
        Object.entries(value).forEach(([subkey, subval]) =>
          entries.push([`${key}.${subkey}`, subval])
        );
      }
      return entries;
    }, [])
  );
}

export function parseQueryParams(params) {
  return Object.entries(mapObjKeys(params, camelToSnake))
    .filter(([_key, val]) => !!val)
    .map(([key, val]) => `${key}=${val}`)
    .join('&');
}

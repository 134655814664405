import { getNoCache } from '@/api/fetch';
import { getShopId } from '@/utils/events';

class Renderer {
  constructor(selectors = []) {
    this.queue = [];
    this.selectors = selectors;
    this.handleBodyChange = this.handleBodyChange.bind(this);
    this.observe = this.observe.bind(this);
    this.shopId = getShopId();
  }

  observe() {
    const config = { attributes: false, childList: true, subtree: true };
    const observer = new MutationObserver(this.handleBodyChange);
    observer.observe(document.body, config);
    this.handleBodyChange();
  }

  getfilteredNodes(selector) {
    return Array.from(document.querySelectorAll(selector)).filter(
      (node) => !this.queue.includes(node.dataset.productId)
    );
  }

  handleBodyChange() {
    this.selectors.forEach((node) => {
      const [selector, renderFunc] = node;
      const targets = this.getfilteredNodes(selector);
      if (targets && targets.length) {
        if (this.shopId) {
          getNoCache(`/shop/${this.shopId}/notify-about-event?action=headless`);
        }
        renderFunc([...targets]);
      }
    });
  }
}

export default Renderer;

export async function renderVue(node, props) {
  const [vue, { createPinia }, { loadI18n }] = await Promise.all([
    import('vue'),
    import('pinia'),
    import('@/i18n/index'),
    import(/* webpackPreload: true */ '@/i18n/lang.json'),
    import(/* webpackPreload: true */ '@/App'), //Added to preload app component
  ]);

  const i18n = await loadI18n();
  const App = vue.defineAsyncComponent(() => import('@/App'));

  const pinia = createPinia();
  const app = vue.createApp(App, props);
  app.use(i18n);
  app.use(pinia);
  app.mount(node);
}

const opts = { rootMargin: '250px', threshold: 0.1 };

export const lazyLoad = (node, props) => {
  const observer = new IntersectionObserver((els) => {
    if (els.some((e) => e.isIntersecting)) {
      observer.disconnect();
      renderVue(node, props);
    }
  }, opts);
  window.addEventListener(
    'click',
    () => {
      observer.disconnect();
      renderVue(node, props);
    },
    { once: true, capture: true }
  );
  observer.observe(node);
};

import { WIDGET_NODES } from './utils/constants';
import { lazyLoad } from '@/libs/VueRender';
import { getShopId } from '@/utils/events';
import { getNoCache } from '@/api/fetch';
import Stars, { renderSingle } from '@/libs/Stars';
import Widgets from '@/libs/Widgets';
import Renderer from '@/libs/Renderer';
import '@/assets/index.css';

const isProd = process.env.NODE_ENV === 'production';
window.OPW_WIDGETS_VERSION = process.env.OPW_WIDGETS_VERSION;

if (
  isProd &&
  window.OPW_PUBLIC_KEY &&
  (window.OPW_CHECKER_ENDPOINT || process.env.VUE_APP_CHECKER_ENDPOINT)
) {
  window.addEventListener('load', () => {
    import('./utils/opwchecker' /* webpackChunkName: "opw-checker" */);
  });
}

WIDGET_NODES.map(([id, type]) => [document.getElementById(id), type]).forEach(
  ([node, type]) => node && lazyLoad(node, { [type]: true })
);

const opwSelector = isProd ? '[href*="opinew"]' : '';

//
// WEB Components section:
//
class OpinewPlugin extends HTMLElement {
  constructor() {
    super();
    this.attachStyles = this.attachStyles.bind(this);
    this.resetApp = this.resetApp.bind(this);
    this.renderApp = this.renderApp.bind(this);
    this.shadowDom = this.attachShadow({ mode: 'open' });
    this.root = document.createElement('div');
    this.shadowDom.appendChild(this.root);
    this.setStyleWatcher();
    this.shopId = getShopId();
  }

  static get observedAttributes() {
    return ['type', 'domain'];
  }

  get type() {
    return this.getAttribute('type');
  }

  set type(val) {
    this.setAttribute('type', val);
  }

  get domain() {
    return this.getAttribute('domain');
  }

  set domain(val) {
    this.setAttribute('domain', val);
  }

  get productId() {
    return this.getAttribute('product-id');
  }

  set productId(val) {
    this.setAttribute('product-id', val);
  }

  get theme() {
    return this.getAttribute('theme');
  }

  set theme(val) {
    this.setAttribute('theme', val);
  }

  get lang() {
    return (this.getAttribute('lang') || '').toUpperCase();
  }

  set lang(val) {
    this.setAttribute('lang', val);
  }

  attributeChangedCallback() {
    if (this.mounted) {
      this.resetApp();
    }
  }

  async connectedCallback() {
    await this.renderApp();
    this.mounted = true;
  }

  async renderApp() {
    if (!this.type) {
      throw new Error('Opinew Plugin: widget type not set');
    }
    if (this.type.includes('stars')) {
      await this.renderStars();
    } else {
      this.renderVue();
    }

    if (this.shopId) {
      getNoCache(`/shop/${this.shopId}/notify-about-event?action=headless`);
    }
  }

  scrollToWidget() {
    const yOffset = -120;
    let el = document.querySelector('opinew-plugin[type="product"]');
    if (el) {
      el = el.shadowRoot.querySelector('div.opinew-widget-wrapper');
      const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }

  async renderStars() {
    if (this.type) {
      await renderSingle({
        node: this.root,
        [this.type.includes('collection') ? 'collectionIds' : 'productIds']: [
          this.productId,
        ],
      });
      const el = this.root.firstElementChild;
      el && el.classList.add('opw-cursor-pointer', 'opw-inline-block');
      if (el && this.type === 'product-stars') {
        el.addEventListener('click', this.scrollToWidget);
      }
    }
  }

  renderVue() {
    lazyLoad(this.root, {
      [this.type]: true,
      lang: this.lang,
      productId: this.productId,
      domain: this.domain,
      theme: this.theme,
    });

    //TODO: handle event bus

    this.root.addEventListener('OpinewWidgetLoaded', () => {
      this.dispatchEvent(
        new CustomEvent('OpinewWidgetLoaded', { bubbles: true })
      );
    });
  }

  attachStyles(mutations) {
    mutations
      .map((m) => [...m.addedNodes])
      .flat()
      .forEach((node) => {
        const isValid =
          1 || !isProd || (node.href && node.href.includes('opinew'));
        if (node.rel === 'stylesheet' && isValid) {
          this.shadowDom.insertBefore(
            node.cloneNode(true),
            this.shadowDom.lastElementChild
          );
        }
      });
  }

  setStyleWatcher() {
    const stylesheets = [
      ...document.querySelectorAll(
        '#opinew_product_plugin_css,link[rel="stylesheet"]' + opwSelector
      ),
    ];

    stylesheets.forEach((node) => {
      this.shadowDom.insertBefore(
        node.cloneNode(true),
        this.shadowDom.lastElementChild
      );
    });

    const config = { attributes: true, childList: true, subtree: true };
    const observer = new MutationObserver(this.attachStyles);
    observer.observe(document.head, config);
  }

  resetApp() {
    this.vueApp && this.vueApp.$destroy();
    this.root = this.appRoot || this.root;
    this.renderApp();
  }
}

if (!window.customElements.get('opinew-plugin')) {
  window.customElements.define('opinew-plugin', OpinewPlugin);
}

//
// JS Widgets section:
//
const widgets = Widgets();
const renderer = new Renderer(widgets.concat(Stars));
// Attach mutation observer
if (document.body) {
  renderer.observe();
} else {
  window.addEventListener('DOMContentLoaded', renderer.observe);
}
